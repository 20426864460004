import React, { useContext } from 'react';

import { FiFolder } from 'react-icons/fi';

import { AppContext } from '../context/AppState';
import { ModalContainer, ModalHeading } from './Modal';
import { PostList, PostItem, PostLink, PostListHeading } from './shared/PostList';
import { AttributeList, AttributeItem, AttributeButton, Instruction } from './shared/Attribute';
import postList from '../data/postListQuery';
import { attributesToOrderedArray } from '../utils/helpers/attributes';
import { UIContext } from '../context/UIState';

const Categories = ({ closeModal }) => {
  const { selectedCategory, setSelectedCategory } = useContext(AppContext);
  const { slideOutNavigator } = useContext(UIContext);
  const posts = postList();
  const categories = {};

  posts.forEach((post) => {
    const { category } = post;

    if (category && category !== null) {
      if (!categories[category]) {
        categories[category] = { name: category, numberOfPosts: 0 };
      }

      categories[category].numberOfPosts += 1;
    }
  });

  const orderedCategories = attributesToOrderedArray(categories);

  const filteredPosts = posts.filter((item) => item.category === selectedCategory);

  const postLinkOnClick = () => {
    closeModal();
    slideOutNavigator();
  };

  const categoryButtonOnClick = (categoryName) => () => {
    if (categoryName !== selectedCategory) {
      setSelectedCategory(categoryName);
    } else {
      setSelectedCategory('');
    }
  };

  const getPhrase = (numberOfPosts) => {
    if (numberOfPosts === 1) {
      return (
        <>
          <strong>1</strong> post
        </>
      );
    }

    return (
      <>
        <strong>{numberOfPosts}</strong> posts
      </>
    );
  };

  return (
    <ModalContainer>
      <ModalHeading>
        <FiFolder />
        Categorias
      </ModalHeading>
      <Instruction>Selecione a categoria: </Instruction>
      <AttributeList>
        {orderedCategories.map((category) => (
          <AttributeItem key={category.name}>
            <AttributeButton
              onClick={categoryButtonOnClick(category.name)}
              className={selectedCategory === category.name ? 'active' : ''}
            >
              {category.name} {category.numberOfPosts}
            </AttributeButton>
          </AttributeItem>
        ))}
      </AttributeList>
      {selectedCategory && (
        <PostListHeading>
          Há {getPhrase(categories[selectedCategory].numberOfPosts)} na categoria: <strong>{selectedCategory}</strong>
        </PostListHeading>
      )}
      <PostList>
        {filteredPosts.map((post) => (
          <PostItem key={post.slug}>
            <PostLink to={post.slug} onClick={postLinkOnClick}>
              {post.title}
            </PostLink>
          </PostItem>
        ))}
      </PostList>
    </ModalContainer>
  );
};

export default Categories;
