import React from 'react';
import { Helmet } from 'react-helmet';

 const SeoComp = ({ location, data }) => {

  const siteTitle = 'Caio Santesso';
  const isBlogPost = () => data && data.post;
  const pageTitle = () => (isBlogPost() ? data.post.frontmatter.title + ' | ' : '') + siteTitle;
  const description = () =>
    isBlogPost() ? data.post.frontmatter.description : 'Tutoriais simples sobre Java, Bash e afins em português.';

  return (
    <Helmet>
      <html lang="pt" />
      <meta charset="utf-8" />
      <title>{pageTitle()}</title>
      <meta name="description" content={description()} />
      <meta property="og:url" content={location.href} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={pageTitle()} />
      <meta property="og:description" content={description()} />
      <meta property="og:type" content={isBlogPost() ? 'article' : 'website'} />
      {isBlogPost() ? <meta property="article:author" content={siteTitle} /> : null}
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:image" content="https://caiosantesso.dev/cs_opengraph.png" />
    </Helmet>
  );
};

export default SeoComp;