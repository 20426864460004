export default function isDark() {
  const savedTheme = window.localStorage.getItem('darkTheme');
  return savedTheme === null ? window.matchMedia('(prefers-color-scheme: dark)').matches : savedTheme === 'true';
}

export function isDarkSafe() {
  if (typeof window === 'undefined') {
    return false;
  } else {
    return isDark();
  }
}
