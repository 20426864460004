import React, { createContext, useState, useEffect } from 'react';

import colours from '../styles/theme/colours';
import isDark from '../utils/helpers/checkDarkTheme'

export const ThemeContext = createContext();

export const ThemeContextProvider = (props) => {
  const [isDarkTheme, setDarkTheme] = useState(undefined);

  useEffect(() => {
    setDarkTheme(isDark());
  }, []);

  function toggleDarkTheme(isDark) {
    setDarkTheme(isDark);
    window.localStorage.setItem('darkTheme', isDark);
    const theme = isDark ? colours.dark : colours.light;
    for (let key in theme) {
      document.documentElement.style.setProperty('--' + key, theme[key]);
    }
  }

  return (
    <ThemeContext.Provider value={{ isDarkTheme: isDarkTheme, setDarkTheme: toggleDarkTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
