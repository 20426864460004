import React from 'react';
import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';

const Header = styled.header`
  /* width: 100%; */

  :hover h1 {
    color: var(--primary);
    transition: 1s ease;
  }
`;

const Link = styled(GatsbyLink)`
  text-decoration: none;
`

const Hgroup = styled.hgroup`
  height: 5rem;
  position: relative;
  transition: 0.5s ease, background-color 0s;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.5) 0 1px 1px;
`;

const BaseTitle = styled.h1`
  transition: 0.5s ease, color 0s, background-color 0s;
  font-weight: 100;
`;

const Title = styled(BaseTitle)`
  color: var(--blogTitle);
  font-size: ${(props) => props.theme.fontSizes[`3xl`]};
  text-transform: uppercase;
  letter-spacing: ${(props) => props.theme.spaces[`3xs`]};
  white-space: nowrap;

  .navigatorSlidingUp &,
  .navigatorAside & {
    font-size: ${(props) => props.theme.fontSizes['2xl']};
  }
`;

const Subtitle = styled(BaseTitle)`
  color: var(--homeText);
  font-size: ${(props) => props.theme.fontSizes.s};
  margin-top: ${(props) => props.theme.spaces[`2xs`]};

  .navigatorSlidingUp &,
  .navigatorAside & {
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
`;

const HeaderComp = (props) => {
  const { slideInNavigator } = props;

  return (
    <Header>
      <Link to="/" onClick={slideInNavigator}>
        <Hgroup>
          <Title>Caio Santesso</Title>
          <Subtitle as="h2">Tutoriais sobre Java e afins</Subtitle>
        </Hgroup>
      </Link>
    </Header>
  );
};

export default HeaderComp;