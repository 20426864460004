import React, { useContext } from 'react';
import styled from '@emotion/styled';

import FullScreenBtn from './FullScreenBtn';
import CategoryBtn from './CategoryBtn';
import TagBtn from './TagBtn';
import ScrollBtn from './ScrollBtn';
import HomeBtn from './HomeBtn';
import InfoBtn from './InfoBtn';
import { UIContext } from '../../context/UIState';
import ThemeToggle from './ThemeToggle';

const Actions = styled.aside`
  background: var(--bg);
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 6;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    top: 30px;
    left: calc(100vw - 110px);
    width: 80px;
    padding: ${(props) => props.theme.spaces.s} 0;
    bottom: 30px;
    z-index: 4;
    transition: 0.5s ease, background 0s;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
  }
`;

const ActionsBar = () => {
  const { navigator, navigatorState, slideInNavigator, infoFeatured, setInfoFeatured } = useContext(UIContext);

  return (
    <Actions>
      <Group>
        <HomeBtn navigatorState={navigatorState} slideInNavigator={slideInNavigator} />
        <InfoBtn infoFeatured={infoFeatured} setInfoFeatured={setInfoFeatured} />
        <CategoryBtn />
        <TagBtn />
      </Group>
      <Group>
        <ThemeToggle />
        <ScrollBtn navigatorState={navigatorState} navigator={navigator} />
        <FullScreenBtn />
      </Group>
    </Actions>
  );
};

export default ActionsBar;