import React, { useContext } from 'react';
import styled from '@emotion/styled';

import Footer from '../shared/Footer';
import { UIContext } from '../../context/UIState';
import Comments from '../Main/Comments'

const PageContainer = styled.div`
  background: var(--bg);
  right: 0px;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100%;
  overflow-wrap: anywhere; /* mobile links */

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: calc(100% - ${(props) => props.theme.dimensions.sidebar.width} - 104px); /* scrollbar */
    right: 104px;
  }
`;

const PageContainerComp =  ({ children }) => {
  const { navigatorState } = useContext(UIContext);

  return navigatorState !== 'featured' ? (
    <PageContainer>
      <main> {children} </main>
      <Comments location={children.props.location}/>
      <Footer />
    </PageContainer>
  ) : null;
};

export default PageContainerComp;