export const colours = {
  light: {
    bg: '#fcfcff',
    // bg: '#2b2a33',
    blogTitle: '#111',
    homeText: '#555',
    icon: '#999',
    primary: '#009999',
    secondary: '#0277bd',
    pageText: `#444`,
    postBorderLeft: 'rgba(255, 255, 255, 0.1)',
    postBorderRight: 'rgba(34, 34, 34, 0.1)',
    border: 'hsla(0, 0%, 40%, 0.2)'
  },
  dark: {
    bg: '#1d1d1f',
    blogTitle: '#EfEfEf',
    homeText: '#CCC',
    icon: '#999',
    primary: '#009688',
    secondary: '#0277bd',
    pageText: `#DDD`,
    postBorderLeft: 'rgba(34, 34, 34, 0.1)',
    postBorderRight: 'rgba(255, 255, 255, 0.1)',
    border: 'hsla(0, 0%, 40%, 0.2)'
  },
};

export default colours;
