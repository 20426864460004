import React, {useContext} from 'react';
import styled from '@emotion/styled';

import pageLinks from '../../data/pageLinks';
import Anchor from '../shared/Anchor';
import { UIContext } from '../../context/UIState';


const PageLinks = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  opacity: 1;
  margin-top: ${(props) => props.theme.spaces['5xl']};
  transition: 0.5s ease;

  .navigatorAside &,
  .navigatorSlidingUp & {
    opacity: 0;
  }
`;

const PageLink = styled.li`
  a {
    display: block;
    padding: ${(props) => props.theme.spaces.s};
  }
`;

 const PageLinkComp = (props) => {
  const links = pageLinks();
  const { slideOutNavigator } = useContext(UIContext);

  return (
    <PageLinks>
      {links.map((link) => {
        return (
          <PageLink key={link.label}>
            <Anchor href={link.to} onClick={slideOutNavigator}>{link.label}</Anchor>
          </PageLink>
        );
      })}
    </PageLinks>
  );
};

export default PageLinkComp;