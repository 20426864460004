import React, { useState } from 'react';
import screenfull from 'screenfull';
import styled from '@emotion/styled';

import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';

import IconButton from './IconButton';

const FullScreen = styled(IconButton)`
  svg {
    width: 22px;
    height: 22px;

    @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
      width: 34px;
      height: 34px;
    }
  }
`;

const FullScreenComp = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  if (screenfull.enabled) {
    screenfull.on('change', () => {
      setIsFullscreen(screenfull.isFullscreen);
    });
  }

  return (
    <FullScreen onClick={() => screenfull.toggle()} ariaLabel="Maximiza a janela.">
      {isFullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
    </FullScreen>
  );
};

export default FullScreenComp;