import styled from '@emotion/styled';

export const H1 = styled.h1`
  color: var(--primary);
  font-size: ${(props) => props.theme.fontSizes[`3xl`]};
  letter-spacing: -0.03em;
  font-weight: 500;
  line-height: 1.15;

  counter-reset: h2counter;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: ${(props) => props.theme.fontSizes[`5xl`]};
  }
`;

export const Subtitle = styled.h2`
  color: var(--primary);
  font-size: ${(props) => props.theme.fontSizes.m};
  line-height: 1.15;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: ${(props) => props.theme.fontSizes[`xl`]};
  }
`;

export const Description = styled.p`
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizes.s};
  margin-top: 1rem;
`;

export const H2 = styled.h2`
  color: var(--pageText);
  font-size: ${(props) => props.theme.fontSizes[`xl`]};
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-top: ${(props) => props.theme.spaces[`2xl`]};

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: ${(props) => props.theme.fontSizes[`3xl`]};
  }

  ::before {
    content: counter(h2counter) '. ';
    counter-increment: h2counter;
  }
`;

export const H3 = styled.h3`
  color: var(--pageText);
  font-size: ${(props) => props.theme.fontSizes.l};
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-top: ${(props) => props.theme.spaces.xl};

  counter-reset: questioncounter;

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: ${(props) => props.theme.fontSizes.xl};
  }
`;

export const InlineCode = styled.code`
  background-color: hsla(180, 100%, 30%, 0.2);
  letter-spacing: -0.02rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  font-size: 95%;
`;

export const IconButton = styled.button`
  width: 3rem;
  cursor: pointer;
  background: none;
  border: none;

  svg {
    color: var(--pageText);
    transition: 0.5s ease-out;
    width: 1.5rem;
    height: 1.5rem;
  }

  :hover {
    transition: 0.5s ease-out;
    svg {
      transform: scale(1.2);
      stroke-width: 3;
      color: var(--primary);
    }
  }
`;

export const Table = styled.table`
  margin-top: ${(props) => props.theme.spaces.m};
  margin-bottom: ${(props) => props.theme.spaces.xl};
  border-collapse: collapse;
  border-bottom: 1px solid var(--postBorderRight);

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }


  th {
    border-bottom: 1px solid var(--postBorderRight);
  }

  th,
  td {
    padding: ${(props) => props.theme.spaces['2xs']} ${(props) => props.theme.spaces.s};
  }
`;

export const Article = styled.article`
  max-width: 43rem;
  margin: ${(props) => props.theme.spaces.xl} auto ${(props) => props.theme.spaces.xl};
  color: var(--pageText);
  line-height: 1.7;
  padding: 0 ${(props) => props.theme.spaces.l};

  h2 + h3 {
    margin-top: ${(props) => props.theme.spaces.l};
  }

  h3 + p,
  div + p {
    margin-top: ${(props) => props.theme.spaces.l};
  }

  p:nth-of-type(2) {
    margin-top: ${(props) => props.theme.spaces.xl};
  }

  > p:last-child {
    margin-top: ${(props) => props.theme.spaces[`3xl`]};
  }

  ul {
    list-style-type: '⇁  ';
  }

  ol,
  ul {
    margin-top: ${(props) => props.theme.spaces.m};
  }

  li {
    margin-top: ${(props) => props.theme.spaces.s};
    margin-left: ${(props) => props.theme.spaces.l};
  }

  li > * > li {
    margin-top: ${(props) => props.theme.spaces[`2xs`]};
  }

  ol > * > ol,
  ul > * > ul {
    margin-top: 0;
  }


  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-top: ${(props) => props.theme.spaces.xl};
  }
`;

export const P = styled.p`
  margin-top: ${(props) => props.theme.spaces.m};
`;
