import React from 'react';
import { Global, css, useTheme } from '@emotion/react';

const ThemeStyles = () => {
  const theme = useTheme();

  const themedStyle = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      html {
        scrollbar-color: var(--primary) var(--bg);
        scrollbar-width: thin;
      }

      ::-webkit-scrollbar-track {
        background: var(--bg);
      }

      ::-webkit-scrollbar-thumb {
        background-color: var(--primary);
      }
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    }

    ::selection {
      background-color: var(--secondary);
      color: white;
    }
  `;

  return <Global styles={themedStyle} />;
};

export default ThemeStyles;
