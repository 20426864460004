import React from 'react';
import { FiArrowUp } from 'react-icons/fi';
import IconButton from './IconButton';

const ScrollBtnComp =  ({ navigatorState, navigator }) => {
  const scrollOptions = {
    left: 0,
    top: 0,
    behavior: 'smooth',
  };

  const scrollPost = () => {
    window.scrollTo(scrollOptions);
  };

  const scrollNavigator = () => {
    navigator.current.scrollTo(scrollOptions);
  };

  return (
    <IconButton
      onClick={navigatorState === 'aside' ? scrollPost : scrollNavigator}
      ariaLabel="Rola página para o topo."
    >
      <FiArrowUp />
    </IconButton>
  );
};

export default ScrollBtnComp;