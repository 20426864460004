import { useStaticQuery, graphql } from 'gatsby';

const AboutMeQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fileAbsolutePath: { regex: "/pieces/about-me/" } }) {
          edges {
            node {
              body
              fields {
                source
              }
            }
          }
        }
      }
    `
  );

  const {
    allMdx: { edges },
  } = data;

  const nodes = edges.map((edge) => edge.node);
  return nodes[0].body;
};

export default AboutMeQuery;
