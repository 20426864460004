import React from 'react';
import styled from '@emotion/styled';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import aboutMeQuery from '../../data/aboutMeQuery';
import Tux from '../../../content/images/tux.inline.svg';

const Note = styled.div`
  font-size: ${(props) => props.theme.fontSizes.s};
  color: var(--homeText);
  line-height: 2;
  text-align: center;
  padding: 0 ${(props) => props.theme.spaces.m};
  margin-top: ${(props) => props.theme.spaces['4xl']};
  transition: 0.5s ease;
  opacity: 1;

  .navigatorAside &,
  .navigatorSlidingUp & {
    opacity: 0;
  }

  svg {
    vertical-align: text-bottom;
    color: var(--primary);
    height:${(props) => props.theme.fontSizes.l};
    width: ${(props) => props.theme.fontSizes.l};
  }
`;

 const NoteComp = (props) => {
  const aboutMe = aboutMeQuery();

  return (
    <MDXProvider
      components={{
        wrapper: Note,
        Tux
      }}
    >
      <MDXRenderer >{aboutMe}</MDXRenderer>
    </MDXProvider>
  );
};

export default NoteComp;