import React, { useRef, useContext } from 'react';
import { FiToggleLeft, FiToggleRight } from 'react-icons/fi';

import { ThemeContext } from '../../context/ThemeState';
import IconButton from './IconButton';

const ThemeToggleComp = () => {
  const btnRef = useRef(null);
  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);

  if (typeof isDarkTheme === 'undefined') {
    return null;
  }

  return (
    <IconButton
      btnRef={btnRef}
      onClick={() => {
        setDarkTheme(!isDarkTheme);
      }}
      ariaLabel="Alterna temas."
    >
      {isDarkTheme ? <FiToggleLeft /> : <FiToggleRight />}
    </IconButton>
  );
};

export default ThemeToggleComp;