import { useStaticQuery, graphql } from 'gatsby';
// TODO warn The enum value "MdxFieldsEnum.fields___date" is deprecated. Sorting on fields that need arguments to resolve is deprecated.
// Queried in /home/caio/workspaces/caiosantesso.dev/src/data/postListQuery.js

// warn [gatsby-transformer-sharp] The "fixed" and "fluid" resolvers are now deprecated. Switch to "gatsby-plugin-image" for better performance and a simpler API. See
// https://gatsby.dev/migrate-images to learn how.
const PostListQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      posts: allMdx(
        filter: { fields: { source: { in: ["personal-blog-posts"] }, slug: { ne: null } } }
        sort: { fields: [fields___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
              date
            }
            frontmatter {
              title
              subTitle
              category
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 90, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    posts: { edges },
  } = data;

  const posts = edges.map((edge) => {
    const {
      frontmatter: {
        title,
        subTitle,
        category,
        tags,
        cover: {
          childImageSharp: { fluid: imgData },
        },
      },
      fields: { slug, date },
    } = edge.node;

    return {
      title,
      subTitle,
      imgData,
      category,
      tags,
      slug,
      date,
    };
  });

  return posts;
};

export default PostListQuery;
