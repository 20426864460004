import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';
import { FiExternalLink } from 'react-icons/fi';

export const A = styled.a`
  color: var(--primary);
  text-decoration: none;
  background-image: linear-gradient(var(--secondary), var(--secondary));
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: all ease-in-out 0.3s;

  :hover {
    color: var(--secondary);
    background-size: 100% 2px;
  }
`;

const Link = A.withComponent(GatsbyLink);

const Anchor = (props) => {
  const to = props.href;
  const internal = /^\/(?!\/)/.test(to);

  return internal ? (
    <Link to={to} {...props} />
  ) : !props.iconified ? (
    <span>
      <A target="_blank" rel="noopener" {...props} /> <FiExternalLink />
    </span>
  ) : (
    <A target="_blank" rel="noopener" {...props} />
  );
};

export default Anchor;
