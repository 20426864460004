import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import queryLicence from '../../data/licenceQuery';
import Anchor from '../shared/Anchor';

const Footer = styled.footer`
  margin: ${(props) => props.theme.spaces['4xl']} 0;
  color: var(--pageText);

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: ${(props) => props.theme.spaces['4xl']} 0 ${(props) => props.theme.spaces.xl};
  }

  &.inSidebar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: 0.5s ease;

    .navigatorFeatured &,
    .navigatorSlidingIn & {
      transform: translateY(0);
    }
  }
`;

const LincenceComp = styled.div`
  font-size: ${(props) => props.theme.fontSizes[`xs`]};

  ul {
    list-style: none;
    display: flex;

    justify-content: center;
  }

  li {
    text-align: center;
    padding: ${(props) => props.theme.spaces[`3xs`]} ${(props) => props.theme.spaces.xs};
  }

  .inSidebar & {
    ul {
      flex-direction: column;
      font-size: ${(props) => props.theme.fontSizes[`2xs`]};
    }
  }
`;

const FooterComp = ({ inSidebar = false }) => {
  const licenceNote = queryLicence();

  return (
    <Footer className={inSidebar ? `inSidebar` : ``}>
      <MDXProvider
        components={{
          wrapper: LincenceComp,
          a: Anchor,
        }}
      >
        <MDXRenderer>{licenceNote}</MDXRenderer>
      </MDXProvider>
    </Footer>
  );
};

FooterComp.propTypes = {
  inSidebar: PropTypes.bool,
};

export default FooterComp;
