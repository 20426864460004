import breakpoints from './breakpoints';
import dimensions from './dimensions';
import fontSizes from './fontSizes';
import spaces from './spaces';

const Theme = {
  breakpoints,
  dimensions,
  fontSizes,
  spaces,
};

export default Theme;