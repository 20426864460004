import React, { useRef, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { ThemeContext } from '../../context/ThemeState';
import { H2 } from '../shared/SystemDesign';

export const CommentSection = styled.div`
  max-width: 43rem;
  /* margin: ${(props) => props.theme.spaces['3xl']} auto; */
  margin: auto;
  padding: 0 ${(props) => props.theme.spaces.l};
`;

const SectionTitle = styled(H2)`
  margin-bottom: ${(props) => props.theme.spaces.l};

  ::before {
    content: unset;
  }
`;

const CommentsComp = ({ location }) => {
  const commentDiv = useRef(null);
  const { isDarkTheme } = useContext(ThemeContext);

  useEffect(() => {
    const scriptEl = document.createElement('script');
    scriptEl.src = 'https://utteranc.es/client.js';
    scriptEl.async = true;
    scriptEl.setAttribute('repo', 'caiosantesso/caiosantesso.dev_comments');
    scriptEl.setAttribute('issue-term', 'url');
    scriptEl.setAttribute('theme', isDarkTheme ? 'photon-dark' : 'github-light');
    scriptEl.setAttribute('crossorigin', 'anonymous');

    const currentCommmentDiv = commentDiv.current;
    if (commentDiv && currentCommmentDiv) {
      currentCommmentDiv.appendChild(scriptEl);
    } else {
      console.log(`Error adding utterances comments on: ${commentDiv}`);
    }

    return () => {
      while (currentCommmentDiv.firstChild) currentCommmentDiv.removeChild(currentCommmentDiv.firstChild);
    };
  }, [isDarkTheme, location]);

  return (
    <CommentSection>
      <hr />
      <SectionTitle>Comentários</SectionTitle>
      <div ref={commentDiv} />
    </CommentSection>
  );
};

export default CommentsComp;
