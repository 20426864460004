module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"personal-blog-pieces":"/opt/build/repo/src/templates/PieceTemplate.js","default":"/opt/build/repo/src/templates/PageTemplate.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":640,"backgroundColor":"none","quality":90}}],"remarkPlugins":[null],"extensions":[".mdx"],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Caio Santesso","short_name":"Caio Santesso","start_url":"/","lang":"pt","background_color":"#222","theme_color":"#009688","display":"minimal-ui","icon":"content/images/cs_logo.svg","icon_options":{"purpose":"badge"},"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"1593c5fb4daa239ede18620d4ce1e464"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-100235873-2","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":100,"siteSpeedSampleRate":100,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
