import React from 'react';
import { Global, css } from '@emotion/react';

const globalStyle = css`
  :root {
    -moz-tab-size: 4;
    tab-size: 4;
    --reach-menu-button: 1;
    --reach-dialog: 1;
  }

  html {
    box-sizing: border-box;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  *,
  *:after,
  *:before {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: var(--bg);
    margin: 0;
    font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
    position: relative;
  }

  hr {
    height: 0;
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  pre,
  kbd,
  samp {
    font-family: 'JetBrains Mono', 'Ubuntu Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  *,
  *:focus,
  *:hover {
    outline: none;
  }

  a {
    text-decoration: none;
  }

  dnf[title] {
    text-decoration: underline dotted;
  }
`;

const GlobalStyles = () => {
  return <Global styles={globalStyle} />;
};

export default GlobalStyles;
