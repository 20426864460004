import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';

import ThemeContextProvider from '../../context/ThemeState';
import theme from '../../styles/theme';
import GlobalStyles from '../../styles/GlobalStyles';
import GlobalFonts from '../../styles/GlobalFonts';
import Navigator from '../Navigator';
import Info from '../Info';
import PageContainer from './PageContainer';
import ActionsBar from '../ActionsBar';
import { UIProvider } from '../../context/UIState';
import { AppProvider } from '../../context/AppState';
import { ModalProvider } from '../Modal';
import ThemeStyles from '../../styles/ThemeStyles';
import Seo from './Seo';

const Layout = (props) => {
  const { children, location, data } = props;

  return (
    <>
      <Seo location={location} data={data} />
      <GlobalStyles />
      <GlobalFonts />
      <ThemeContextProvider>
        <UIProvider location={location}>
          <AppProvider>
            <ThemeProvider theme={theme}>
              <ModalProvider>
                <ThemeStyles />
                <Info />
                <ActionsBar />
                <Navigator />
                <PageContainer>{children}</PageContainer>
              </ModalProvider>
            </ThemeProvider>
          </AppProvider>
        </UIProvider>
      </ThemeContextProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
