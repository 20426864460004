import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalFonts = () => (
  <Global
    styles={css`
      body {
        font-family: 'Ubuntu', sans-serif;
        font-size: 1rem;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 300;
      }
      pre,
      code {
        font-family: 'JetBrains Mono', monospace;
        font-size: 0.90rem;
      }
    `}
  />
);

export default GlobalFonts;
